import './Horsie.css';
import horsie from './horsie.png';

function Horsie() {
  return (
    <div className="Horsie">
      <center>
        <img src = {horsie} className="Horsie-logo" alt="logo" />
      </center>   
    </div>
  );
}

export default Horsie;
