import './Tile.css';

function Tile() {
  return (
      <div id="message">
              <h2>Welcome to williamstawicki.com</h2>
              <br />
              <h1>This 🐶 is in the works...</h1>
              <br />
              <p>Content on its way!</p>
      </div>
  );
}

export default Tile;